@import 'variables.scss';

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

// скругление универсальное
// пример: @include br(3px);
@mixin br($rad) {
  border-radius: $rad;
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
      $o: nth($args, $i + 1);
    }
  }
  position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
  @include position(absolute, $args);
}

@mixin fixed($args: '') {
  @include position(fixed, $args);
}

@mixin relative($args: '') {
  @include position(relative, $args);
}

// Define default font size
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: calculateRem($size) !important;
}

// px to rem
@function rem($pixels, $context: $mainFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

/* Fonts Helpers:
   Read more: https://github.com/vedees/webpack-template/blob/master/README.md#add-fonts
   ========================================================================== */

/*
  By default template support only modern format fonts
  Without svg. Only: .woff, .woffs
  if ypu need svg comments lines bellow and uncomments "Fonts helper all format fonts"
  Usage:
    1. Put your font to `src/assets/fonts/FOLDERNAME/FONTNAME`
      FOLLOW: Files Required
      Example: .woff, .woffs formats
    2. Go to fonts.scss
    3. Use mixin
      Example: @include font-face("OpenSans", "../fonts/OpenSans/opensans");
    Required parameters: @family, @family
    Minor parameters: @weight, @style, @pipeline
    Example Base:
      @include font-face("OpenSans", "../fonts/OpenSans/opensans");
    Example Situation:
      @include font-face("OpenSans", "../fonts/OpenSans/opensansItalic", 400, italic);
*/

// Default: For modern format fonts
// Files Required: .woff, .woffs

@mixin font-face(
  $font-family,
  $file-path,
  $weight: normal,
  $style: normal,
  $asset-pipeline: false
) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: block;

    src: url("#{$file-path}.woff") format("woff"),
    url("#{$file-path}.eot") format("eot"),
    url("#{$file-path}.ttf") format("ttf");
  }
}

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

// return css color variable with different opacity value
@function alpha($color, $opacity){
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsla($color-h, $color-s, $color-l, $opacity);
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@mixin defineColorHSL($color, $hue, $saturation, $lightness){
  #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");#{$color}-h: #{$hue};#{$color}-s: #{$saturation};#{$color}-l: #{$lightness};
}
