@import './variables.scss';
@import './mixins.scss';
@include font-face('Segoe UI', '../assets/fonts/segoeui/SegoeUIRegular/SegoeUIRegular');
@include font-face('Segoe UI Light', '../assets/fonts/segoeui/SegoeUILight/SegoeUILight');
@include font-face('Segoe UI Semibold', '../assets/fonts/segoeui/SegoeUISemiBold/SegoeUISemiBold');

html {
  line-height: $mainLineHeight;
  font-size: $mainFontSize;
  color: $mainFontColor;
  height: 100%;
  @media screen and (max-width: $smDesktopWidth) {
    font-size: 14px;
  }
  @media screen and (max-width: $tableWidth) {
    font-size: 13px;
  }
  @media screen and (max-width: $smTableWidth) {
    font-size: 12px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #C4C4C4 transparent;
}

body {
  height: 100%;
  background-color: #F2F2F2;
  overflow: hidden;
  font-family: 'Segoe UI', sans-serif;
}

#app {
  height: 100%;
}

#ad-banner {
  height: 1px;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
  border-radius: 4px;
  border: 4px solid transparent;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sortableHelper {
  z-index: 1501;
  pointer-events: none;
}

//.react-grid-layout {
//  position: relative;
//  transition: height 200ms ease;
//}
.react-grid-item {
  transition: all 100ms ease;
  transition-property: left, top;
}
//.react-grid-item img {
//  pointer-events: none;
//  user-select: none;
//}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
//.react-grid-item.resizing {
//  z-index: 1;
//  will-change: width, height;
//}
//
//.react-grid-item.react-draggable-dragging {
//  transition: none;
//  z-index: 3;
//  will-change: transform;
//}
//
//.react-grid-item.dropping {
//  visibility: hidden;
//}
//
//.react-grid-item.react-grid-placeholder {
//  //background: red;
//  opacity: 0.2;
//  transition-duration: 100ms;
//  z-index: 2;
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  -o-user-select: none;
//  user-select: none;
//}
//

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 10;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid #757575;
  border-bottom: 2px solid #757575;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.recharts-brush-texts {
  :first-child {
    transform: translate(rem(56), 0);
    fill: #000000;
    font-family: "Segoe UI Semibold", sans-serif;
    @include font-size(13px);
  }
  :last-child {
    transform: translate(rem(-56), 0);
    fill: #000000;
    font-family: "Segoe UI Semibold", sans-serif;
    @include font-size(13px);
  }
}

.sortableHelper {
  .point-line {
    display: none;
  }
  .drag-icon {
    display: flex;
  }
}

@keyframes blinding {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.table-scroll {
  position: relative;
  width:100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
}
.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  min-height: 100%;
}
.table-scroll th,
.table-scroll td {
  vertical-align: top;
}
.table-scroll thead,
.table-scroll thead th,
.table-scroll thead td  {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index:4;
}
.table-scroll tbody {
  overflow: auto;
}
.table-scroll thead tr, .table-scroll tbody tr, .table-scroll tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;/* even columns width , fix width of table too*/
}
.table-scroll tbody tr td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
.table-scroll thead th:first-child {
  z-index: 4;
  background-color: #FFFFFF;
}
.table-scroll tfoot th:first-child {
  z-index: 5;
  background-color: #FFFFFF;
}
.table-scroll tfoot td {
  border-bottom: none;
  border-top: 1px solid rgba(224, 224, 224, 1);
}


