$date-bg-color-hover: #E5E5E5;
$selected-bg-color: #35C297;
$selected-range-bg-color: #96E4CC;
$selected-range-bg-color-hover: #62D6B3;
$border-color: #f5f7f8;
@import './variables.scss';
@import './mixins.scss';

:root {
  --primary-color: #676CE0;
}

.rdrDefinedRangesWrapper {
  order: 1;
  border-left: 1px solid #eeeeee;
  width: 10rem;
}

.rdrCalendarWrapper {
  position: relative;
}

.rdrInputRanges,
.rdrMonthAndYearPickers {
  display: none;
}

.rdrMonthAndYearWrapper {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.rdrMonths {
  margin-top: 0.75rem;
}

.rdrStaticRanges > .rdrStaticRange {
  border-bottom: none;
}

.rdrMonthAndYearWrapper > button > i {
  display: none;
}

.rdrMonthAndYearWrapper {
  height: 64px;
  padding-left: 8px;
  padding-right: 8px;
}

.rdrMonthAndYearWrapper > .rdrPprevButton {
  //background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z%22%20fill%3D%22%23757575%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  background-image: url("../assets/images/left-icon.svg");
  background-repeat: no-repeat;
  background-size: 75%;
  background-position: center center;
}

.rdrMonthAndYearWrapper > .rdrNextButton {
  //background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z%22%20fill%3D%22%23757575%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  background-image: url("../assets/images/right-icon.svg");
  background-repeat: no-repeat;
  background-size: 75%;
  background-position: center center;
}

.rdrMonth .rdrWeekDays {
  padding-bottom: 8px;
}

.rdrNextPrevButton:hover {
  background-color: #eeeeee;
}

.rdrMonthAndYearWrapper > button {
  background: transparent;
  width: 36px;
  height: 36px;
  border-radius: 36px;
}

.rdrDays > button > .rdrStartEdge {
  background-color: var(--primary-color);
}

.rdrDays > button > .rdrInRange {
  background-color: rgba(103, 108, 224, 0.2);
}

.rdrDays > button > .rdrEndEdge {
  background-color: var(--primary-color);
}

.rdrMonth {
  width: 25em;
  padding: 0 2em 2em 2em;
}

.rdrStartEdge {
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}

.rdrEndEdge {
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
}

.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge {
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
  left: 0;
}

.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrEndEdge {
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
  right: 0;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.rdrMonth .rdrWeekDay {
  color: #757575;
  font-family: 'Segoe UI Semibold', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.rdrMonth .rdrMonthName {
  color: #424242;
  text-align: center;
  font-family: 'Segoe UI Semibold', sans-serif;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 1.5rem;
  width: 60%;
  margin: auto;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.rdrMonth .rdrDays .rdrDayNumber span {
  font-size: 14px;
  line-height: 19px;
  color: #424242;
  font-family: 'Segoe UI', sans-serif;
}

.rdrMonth .rdrDays .rdrDayPassive .rdrDayNumber span {
  display: none;
}

.rdrDayInPreview,
.rdrDayEndPreview,
.rdrDayStartPreview {
  color: var(--primary-color) !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: var(--primary-color);
  width: 12px;
}

.rdrStaticRanges {
  margin: 16px 0;
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #E0E0E0;
}

.rdrStaticRange.rdrStaticRangeSelected {
  color: #424242 !important;
  background-color: #EEEEEE;
  font-family: "Segoe UI Semibold", sans-serif;
}

.rdrStaticRangeLabel {
  font-family: 'Segoe UI', sans-serif;
  font-size: 13px;
  line-height: 18px;
}

.rdrStaticRange.rdrStaticRangeSelected > .rdrStaticRangeLabel {
  font-family: "Segoe UI Semibold", sans-serif;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #424242;
  font-family: "Segoe UI Semibold", sans-serif;
  font-size: 14px;
  line-height: 19px;
}

.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #FFFFFF;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after {
  background: var(--primary-color);
}

.rdrDayEndOfMonth > .rdrInRange {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  right: 2px;
}

.rdrDayStartOfMonth  > .rdrInRange {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  left: 2px;
}

.rdrDayPassive.rdrDayDisabled {
  background-color: #FFFFFF;
}

.rdrDayNumber, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  top: 0;
  bottom: 0;
}

.rdrDayHovered {
  background-color: #C2C4F3;
}

.rdrDayHovered > .rdrDayStartPreview, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  border: none;
  border-radius: 0;
  background-color: rgba(103, 108, 224, 0.4);
  background-blend-mode: multiply;
}

.rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayStartPreview, .rdrDayStartOfWeek .rdrDayEndPreview,
.rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayEndPreview,
.rdrDayStartOfMonth .rdrDayStartPreview, .rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayEndPreview, .rdrDayEndOfMonth .rdrDayInPreview, {
  border: none;
  border-radius: 0;
}

.rdrCalendarWrapper .rdrSelected {
  border-radius: 50%;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  border: none;
}

.rdrCalendarWrapper .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #FFFFFF;
  font-family: "Segoe UI", sans-serif;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  font-family: "Segoe UI Semibold", sans-serif;
}
